var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[(!_vm.competicao)?_c('v-skeleton-loader',{attrs:{"type":"article"}}):_c('v-card',{staticClass:"ma-1 pa-3"},[_c('v-card-title',[_c('div',{staticClass:"headline primary--text"},[_vm._v(_vm._s(_vm.competicao.nome))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.competicao.descricao)+" "),_c('br'),_c('v-btn',{staticClass:"primary",on:{"click":_vm.regulamentoDownload}},[_vm._v(" Clique aqui para baixar o regulamento ")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{attrs:{"src":_vm.competicao.logo_url,"max-height":"350"}})],1)],1),_vm._l((_vm.competicaoEtapa),function(item){return _c('v-row',{key:item.id,staticClass:"ma-1"},[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(item.nome)+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm._.sortBy(item.competicao_prova, 'apuracao_fim_data_hora'),"footer-props":{
                  itemsPerPageOptions:[-1]
                },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.apuracao_inicio_data_hora",fn:function(ref){
                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.apuracao_fim_data_hora",fn:function(ref){
                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.valor",fn:function(ref){
                var value = ref.value;
return [(value !== null)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(value))+" ")]):_c('span',[_vm._v(" Não disponível ")])]}}],null,true)})],1)],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }