import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { competicaoStore, competicaoEtapaStore } from '@/utils/store-accessor';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.id = null;
        this.competicao = null;
        this.competicaoEtapa = null;
        this.headers = [
            { text: 'Nome', value: 'nome' },
            { text: 'Perído da apuração - Inicio', value: 'apuracao_inicio_data_hora' },
            { text: 'Perído da apuração - Fim', value: 'apuracao_fim_data_hora' },
            { text: 'Resultado', value: 'valor' },
        ];
    }
    async regulamentoDownload() {
        window.open(this.competicao.regulamento_url, '_blank');
    }
    async mounted() {
        this.loading = true;
        this.id = this.$router.currentRoute.params.id;
        this.competicao = await competicaoStore.getCompeticao(this.id);
        this.competicaoEtapa = await competicaoEtapaStore.getCompeticaoEtapas(this.id);
        this.loading = false;
    }
};
List = __decorate([
    Component
], List);
export default List;
